const cn = {
  'header.logout': '登出',
  'header.userManual': '用户手册',
  'header.userInfo': '用户信息',
  'header.copy': '复制 ConnectClient ID',
  copy: '复制',
  success: '成功',
  fail: '失败',
  'app.isLoadingChinese': '验证身份中...',
  'app.isLoadingEN': 'Verify identity...',
  // common
  'common.disconnect': '断开连接',
  'common.update': '更新',
  'common.delete': '删除',
  'common.connect': '连接',
  'common.fix': '修复',
  'common.alarm': '警告',
  'common.maintain': '维修',
  'common.release': '发布',
  'common.add': '添加',
  'common.INFO': '消息',
  'common.WARNING': '警告',
  'common.ERROR': '错误',
  'common.CRITICAL': '危急',
  'common.sure': '确认',
  'common.cancel': '取消',
  'common.add.success': '添加成功',
  'common.add.fail': '添加失败',
  'common.edit.success': '修改成功',
  'common.edit.fail': '修改失败',
  'common.delete.success': '删除成功',
  'common.delete.fail': '删除失败',
  'common.sureDelete': '确定删除？',
  'common.sureDelete.content': '你确定要删除',
  'common.sureDelete.end': '吗？',
  'common.clearFilter': '清空搜索条件',
  'common.research': '重新搜索',
  // Add & edit magic connect modal
  'machineModal.placeHolder': '机台名不能为空',
  'machineModal.machineName': '机台名称',
  'machineModal.machineName.invaild': '最多 50 个字',
  'machineModal.MACAddress': 'MAC Address',
  'machineModal.MACAddress.invaild': '请依照规则范例 00:11:99:AA:BB:FF 填写',
  'machineModal.connectType': '连接类型',
  'machineModal.connectMode': '连接模式',
  'machineModal.connectType.placeholder': '选择连接类型',
  'machineModal.connectMode.placeholder': '选择连接模式',
  'machineModal.lng': '经度',
  'machineModal.lat': '纬度',
  'machineModal.description': '描述',
  'machineModal.description.invaild': '最多 200 个字',
  'machineModal.country': '国家',
  'machineModal.country.invaild': '最多 100 个字',
  'machineModal.city': '城市',
  'machineModal.city.invaild': '最多 100 个字',
  'machineModal.serialNumber': '串行号',
  'machineModal.serialNumber.invaild': '请输入串行号',
  'machineModal.serialNumber.invaild12Letter': '仅能输入英数 12 位元',
  'machineModal.remark': '备注',
  'machineModal.remark.invaild': '最多 100 个字',
  // sidebar
  'sidebar.home': '首页',
  'sidebar.add': '添加',
  'sidebar.news': '消息',
  'sidebar.map': '地图',
  'sidebar.logout': '登出',
  // my connect header
  'myConnect.header.updating.message': '等待更新数据',
  'myConnect.header.modify': '修改',
  'myConnect.header.delete': '删除',
  'myConnect.header.delete.fail': '只有在脱机状态才能进行删除',
  'myConnect.header.log': '日志',
  'myConnect.header.refresh': '刷新',
  'myConnect.header.connect': '连接',
  'myConnect.header.connect.userOffline': '用户脱机状态下不能连接',
  'myConnect.header.connect.machineOffline': '机台脱机状态下不能连接',
  'myConnect.header.connect.machineConnected': '机台已连接或连接中状态下不能连接',
  'myConnect.header.connect.machineAdminConnected': '正在连接状态下不能再连接',
  'myConnect.header.disconnect': '断线',
  'myConnect.header.disconnect.selfConnected': '只可以对自己连接的机台进行断线',
  'myConnect.header.disconnect.connected': '只可以对连接中或已连接状态下的机台进行断线',
  'myConnect.header.binding': 'Binding',
  'myConnect.header.binding.machineConnected': '机台已连接或连接中状态下不能 Binding',
  'myConnect.header.binding.success': 'Binding 成功',
  'myConnect.header.binding.fail': 'Binding 失败',
  'myConnect.header.search': '搜索',
  'myConnect.header.filter.macineCreator': '筛选出您创建的机台',
  // 取得 device
  'getDevice.fall': '取得设备失败',
  // connectTable
  'connectTable.status': '状态',
  'connectTable.action': '操作',
  'connectTable.name': '名字',
  'connectTable.description': '描述',
  'connectTable.userConnective': '连接者',
  'connectTable.country': '国家',
  'connectTable.city': '城市',
  'connectTable.remark': '备注',
  'connectTable.nodata': '无数据',
  'connectTable.On-Line': '在线上',
  'connectTable.Connected': '已连线',
  'connectTable.Connecting': '连接中',
  'connectTable.Off-Line': '离线',
  'connectTable.updating': '更新中',
  //connect machine
  'connectMachine.fail': '连线失败',
  'connectMachine.connecting': '正在连线',
  'connectMachine.delete': '已经被删除',
  'connectMachine.disconnect': '已经被断线',
  //disconnect machine
  'disconnectMachine.success': '断开连线成功',
  'disconnectMachine.fail': '断开连线失败',
  // news Modal
  'newsModal.allNews': '所有消息',
  'newsModal.noNews': '没有最新消息',
  'newsModal.search.fail': '取得最新消息失败',
  'newsModal.filterNoNews': '没有搜索到消息',
  // logs Modal
  'logs.noLogs': '没有日志',
  'logs.filterNoLogs': '没有搜索到日志',
  // Log And news
  'logAndNews.back': '返回连接页',
  'logAndNews.export': '导出',
  'logAndNews.log': '日志',
  'logAndNews.undo': '清除搜索',
  'logAndNews.getLogs.failed': '搜索日志失败',
  // All Log filters
  'logAndNews.filters': '筛选',
  'logAndNews.filters.level': '级别',
  'logAndNews.filters.level.placeholder': '全部',
  'logAndNews.filters.startTime': '开始时间',
  'logAndNews.filters.endTime': '结束时间',
  'logAndNews.filters.action': '动作',
  'logAndNews.filters.action.placeholder': '全部',
  'logAndNews.filters.action.fix': '修复',
  'logAndNews.filters.action.alarm': '警告',
  'logAndNews.filters.action.maintain': '维修',
  'logAndNews.filters.action.release': '发布',
  'logAndNews.filters.action.add': '添加',
  'logAndNews.filters.action.connect': '连线',
  'logAndNews.filters.action.disconnect': '断线',
  'logAndNews.filters.action.update': '更新',
  'logAndNews.filters.action.delete': '删除',
  // Add device
  'deviceModal.name': '名称',
  'deviceModal.name.invaild': '请输入设备名称',
  'deviceModal.ip': 'IP 地址',
  'deviceModal.mask': '遮罩',
  'deviceModal.descr': '描述',
  'deviceModal.type': '类型',
  'deviceModal.otherType': '其他类型',
  'deviceModal.type.placeholder': '选择类型',
  'deviceModal.info': '信息',
  'deviceModal.proto': '连接模式',
  // map
  map: '地图',
  'map.note': '若尚未填入经纬度，并不会在此显示',
  'map.connect': '连接'
};
export default cn;
