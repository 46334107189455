// default
export const TOGGLE_SIDERBAR = 'TOGGLE_SIDERBAR';
export const TOGGLE_ADD_CONNECT = 'TOGGLE_ADD_CONNECT';
export const TOGGLE_NEWS = 'TOGGLE_NEWS';
export const TOGGLE_MAP = 'TOGGLE_MAP';
export const TOGGLE_MACHINE_CHECKED = 'TOGGLE_MACHINE_CHECKED';
export const TOGGLE_COPY_MACHINE_ID_MODAL = 'TOGGLE_COPY_MACHINE_ID_MODAL';
export const TOGGLE_MODIFY_MACHINE_MODAL = 'TOGGLE_MODIFY_MACHINE_MODAL';
export const TOGGLE_DELETE_MACHINE_MODAL = 'TOGGLE_DELETE_MACHINE_MODAL';
export const TOGGLE_FILTER_MACHINE_CREATOR = 'TOGGLE_FILTER_MACHINE_CREATOR';

export const SEARCH_MACHINE = 'SEARCH_MACHINE';
export const REFRESH_MACHINE = 'REFRESH_MACHINE';

export const REQEST_GET_ACCOUNT_INFO = 'REQEST_GET_ACCOUNT_INFO';
export const RECEIVE_GET_ACCOUNT_INFO = 'RECEIVE_GET_ACCOUNT_INFO';

export const REQEST_GET_ALL_MACHINE = 'REQEST_GET_ALL_MACHINE';
export const RECEIVE_GET_ALL_MACHINE = 'RECEIVE_GET_ALL_MACHINE';
export const RECEIVE_GET_ALL_MACHINE_FAIL = 'RECEIVE_GET_ALL_MACHINE_FAIL';

export const RECEIVE_CONTINUOUS_GET_MACHINE = 'RECEIVE_CONTINUOUS_GET_MACHINE';
export const RECEIVE_MACHINE_UPDATING = 'RECEIVE_MACHINE_UPDATING';
export const RECEIVE_CONTINUOUS_GET_MACHINE_HAS_DISCONNECT = 'RECEIVE_CONTINUOUS_GET_MACHINE_HAS_DISCONNECT';

export const CLEAR_ADD_MACHINE_ID = 'CLEAR_ADD_MACHINE_ID';
export const RECEIVE_ADD_MACHINE = 'RECEIVE_ADD_MACHINE';

export const RECEIVE_MODIFY_MACHINE = 'RECEIVE_MODIFY_MACHINE';

export const RECEIVE_DELETE_MACHINE = 'RECEIVE_DELETE_MACHINE';

export const CLEAN_ALL_MACHINE = 'CLEAN_ALL_MACHINE';

export const REQUEST_CONNECT_MACHINE = 'REQUEST_CONNECT_MACHINE';
export const RECEIVE_CONNECT_MACHINE = 'RECEIVE_CONNECT_MACHINE';
export const RECEIVE_CONNECTED_MACHINE = 'RECEIVE_CONNECTED_MACHINE';
export const RECEIVE_CONNECT_MACHINE_FAIL = 'RECEIVE_CONNECT_MACHINE_FAIL';

export const REQUEST_DISCONNECT_MACHINE = 'REQUEST_DISCONNECT_MACHINE';
export const RECEIVE_DISCONNECT_MACHINE = 'RECEIVE_DISCONNECT_MACHINE';
export const RECEIVE_DISCONNECT_MACHINE_FAIL = 'RECEIVE_DISCONNECT_MACHINE_FAIL';
export const RECEIVE_DISCONNECT_PREV_MACHINE = 'RECEIVE_DISCONNECT_PREV_MACHINE';

export const RECEIVE_GET_DEVICE = 'RECEIVE_GET_DEVICE';
export const RECEIVE_GET_DEVICE_FAIL = 'RECEIVE_GET_DEVICE_FAIL';
export const RECEIVE_ADD_DEVICE = 'RECEIVE_ADD_DEVICE';

export const RECEIVE_MODIFY_DEVICE = 'RECEIVE_MODIFY_DEVICE';

export const RECEIVE_DELETE_DEVICE = 'RECEIVE_DELETE_DEVICE';

export const RECEIVE_GET_LOGS = 'RECEIVE_GET_LOGS';

export const RECEIVE_GET_NEWS = 'RECEIVE_GET_NEWS';

export const RECEIVE_USER_STATUS = 'RECEIVE_USER_STATUS';

export const RECEIVE_STATUS_ID = 'RECEIVE_STATUS_ID';

export const REQUEST_BINDED_START = 'REQUEST_BINDED_START';
export const RECEIVE_BINDED = 'RECEIVE_BINDED';
export const REQUEST_BINDED_END = 'REQUEST_BINDED_END';
