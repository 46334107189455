const en = {
  'header.logout': 'Logout',
  'header.userManual': 'User manual',
  'header.userInfo': 'Account',
  'header.copy': 'Copy ConnectClient ID',
  copy: 'Copy',
  success: 'completed',
  fail: 'failed',
  'app.isLoadingChinese': '驗證身份中...',
  'app.isLoadingEN': 'Verifying identity...',
  // common
  'common.disconnect': 'Disconnect',
  'common.update': 'Update',
  'common.fix': 'Fix',
  'common.alarm': 'Alarm',
  'common.maintain': 'Maintain',
  'common.release': 'Release',
  'common.delete': 'Delete',
  'common.connect': 'Connect',
  'common.add': 'Add',
  'common.INFO': 'INFO',
  'common.WARNING': 'WARNING',
  'common.ERROR': 'ERROR',
  'common.CRITICAL': 'CRITICAL',
  'common.sure': 'Confirm',
  'common.cancel': 'Cancel',
  'common.add.success': 'Adding completed',
  'common.add.fail': 'Adding failed',
  'common.edit.success': 'Editing completed',
  'common.edit.fail': 'Editing failed',
  'common.delete.success': 'Deletion completed',
  'common.delete.fail': 'deletion failed',
  'common.sureDelete': 'Are You Sure?',
  'common.sureDelete.content': 'Are you sure you want to delete',
  'common.sureDelete.end': '?',
  'common.clearFilter': 'Clear search criteria',
  'common.research': 'Repeat search',
  // Add & edit magic connent modal
  'machineModal.placeHolder': 'Name cannot be empty',
  'machineModal.machineName': 'Name',
  'machineModal.machineName.invaild': 'Maximum Name length is 50 characters',
  'machineModal.MACAddress': 'MAC Address',
  'machineModal.MACAddress.invaild': 'Invalid MAC format. Example 00:1E:94:AB:CD:EF',
  'machineModal.connectType': 'WAN Connection Type',
  'machineModal.connectMode': 'VPN Connection Mode',
  'machineModal.connectType.placeholder': 'Select connection type',
  'machineModal.connectMode.placeholder': 'Select connection mode',
  'machineModal.lng': 'Longitude',
  'machineModal.lat': 'Latitude',
  'machineModal.description': 'Description',
  'machineModal.description.invaild': 'Maximum description length is 200 characters',
  'machineModal.country': 'Country',
  'machineModal.country.invaild': 'Maximum country name length is 100 characters',
  'machineModal.city': 'City',
  'machineModal.city.invaild': 'Maximum city name length is 100 characters',
  'machineModal.serialNumber': 'Serial number',
  'machineModal.serialNumber.invaild': 'Serial number cannot be empty',
  'machineModal.serialNumber.invaild12Letter': 'Invalid serial number. Serial number must contain 12 characters',
  'machineModal.remark': 'Remark',
  'machineModal.remark.invaild': 'Maximum remark length is 100 characters',
  // sidebar
  'sidebar.home': 'Home',
  'sidebar.add': 'Add',
  'sidebar.news': 'News',
  'sidebar.map': 'Map',
  'sidebar.logout': 'Logout',
  // my connect header
  'myConnect.header.updating.message': 'Waiting for Gateway response',
  'myConnect.header.modify': 'Modify',
  'myConnect.header.delete': 'Delete',
  'myConnect.header.delete.fail': 'Cannot be deleted when device is not offline',
  'myConnect.header.log': 'Open Log',
  'myConnect.header.refresh': 'Refresh',
  'myConnect.header.connect': 'Connect',
  'myConnect.header.connect.userOffline':
    'Cannot connect when ConnectClient status is offline. Please register your ConnectClient first.',
  'myConnect.header.connect.machineOffline':
    'Cannot connect when gateway is offline. Please check your device connection.',
  'myConnect.header.connect.machineConnected': 'Invalid action. Gateway is already connecting or connected',
  'myConnect.header.connect.machineAdminConnected': 'Already connected',
  'myConnect.header.disconnect': 'Disconnect',
  'myConnect.header.disconnect.selfConnected': "Invalid action. No permission to disconnect other user's gateway.",
  'myConnect.header.disconnect.connected': 'Invalid action. Gateway is not in connected or connecting status.',
  'myConnect.header.binding': 'Bind',
  'myConnect.header.binding.machineConnected':
    'Cannot bind in connecting or connected status. Please disconnect first.',
  'myConnect.header.binding.success': 'Binding completed',
  'myConnect.header.binding.fail': 'Binding failed',
  'myConnect.header.search': 'Search',
  'myConnect.header.filter.macineCreator': 'Show your gateways only',
  // ConnectTable
  'connectTable.status': 'Status',
  'connectTable.action': 'Action',
  'connectTable.name': 'Name',
  'connectTable.description': 'Description',
  'connectTable.userConnective': 'Connected user',
  'connectTable.country': 'Country',
  'connectTable.city': 'City',
  'connectTable.remark': 'Remark',
  'connectTable.nodata': 'No data',
  'connectTable.On-Line': 'Online',
  'connectTable.Connected': 'Connected',
  'connectTable.Connecting': 'Connecting',
  'connectTable.Off-Line': 'Offline',
  'connectTable.updating': 'Updating',
  //connect machine
  'connectMachine.fail': 'Connection failed',
  'connectMachine.connecting': 'Connecting',
  'connectMachine.delete': 'Has been deleted',
  'connectMachine.disconnect': 'Has been disconnected',
  //disconnectMachine
  'disconnectMachine.success': 'Disconnecting completed',
  'disconnectMachine.fail': 'Disconnecting failed',
  // news Modal
  'newsModal.allNews': 'All News',
  'newsModal.noNews': 'No News',
  'newsModal.search.fail': 'Getting news failed',
  'newsModal.filterNoNews': 'No news found',
  // logs Modal
  'logs.noLogs': 'No log',
  'logs.filterNoLogs': 'No logs found',
  // All Log
  'logAndNews.back': 'Return',
  'logAndNews.export': 'Export',
  'logAndNews.log': 'Log',
  'logAndNews.undo': 'Clear Filter',
  'logAndNews.getLogs.failed': 'Getting logs failed',
  // All Log filters
  'logAndNews.filters': 'Filters',
  'logAndNews.filters.level': 'Level',
  'logAndNews.filters.level.placeholder': 'All',
  'logAndNews.filters.startTime': 'Start time',
  'logAndNews.filters.endTime': 'End time',
  'logAndNews.filters.action': 'Action',
  'logAndNews.filters.action.placeholder': 'All',
  'logAndNews.filters.action.fix': 'Fix',
  'logAndNews.filters.action.alarm': 'Alarm',
  'logAndNews.filters.action.maintain': 'Maintain',
  'logAndNews.filters.action.release': 'Release',
  'logAndNews.filters.action.add': 'Add',
  'logAndNews.filters.action.connect': 'Connect',
  'logAndNews.filters.action.disconnect': 'Disconnect',
  'logAndNews.filters.action.update': 'Update',
  'logAndNews.filters.action.delete': 'Delete',
  // Add device
  'deviceModal.name': 'Device name',
  'deviceModal.name.invaild': 'Device name cannot be empty',
  'deviceModal.ip': 'IP address',
  'deviceModal.mask': 'Subnet Mask',
  'deviceModal.descr': 'Description',
  'deviceModal.type': 'Type',
  'deviceModal.otherType': 'Other Type',
  'deviceModal.type.placeholder': 'Select Type',
  'deviceModal.info': 'Information',
  'deviceModal.proto': 'Network Protocol',
  // map
  map: 'Map',
  'map.note': 'Make sure your gateway has coordinates added, otherwise it won’t be visible on the map',
  'map.connect': 'Connect'
};
export default en;
