const zh = {
  'header.logout': '登出',
  'header.userManual': '使用者手冊',
  'header.userInfo': '使用者資訊',
  'header.copy': '複製 ConnectClient ID',
  copy: '複製',
  success: '成功',
  fail: '失敗',
  'app.isLoadingChinese': '驗證身份中...',
  'app.isLoadingEN': 'Verify identity...',
  // common
  'common.disconnect': '斷開連線',
  'common.update': '更新',
  'common.delete': '刪除',
  'common.connect': '連線',
  'common.fix': '修復',
  'common.alarm': '警告',
  'common.maintain': '維修',
  'common.release': '發布',
  'common.add': '新增',
  'common.INFO': '訊息',
  'common.WARNING': '警告',
  'common.ERROR': '錯誤',
  'common.CRITICAL': '危急',
  'common.sure': '確認',
  'common.cancel': '取消',
  'common.add.success': '新增成功',
  'common.add.fail': '新增失敗',
  'common.edit.success': '修改成功',
  'common.edit.fail': '修改失敗',
  'common.delete.success': '刪除成功',
  'common.delete.fail': '刪除失敗',
  'common.sureDelete': '確定刪除？',
  'common.sureDelete.content': '你確定要刪除',
  'common.sureDelete.end': '嗎？',
  'common.clearFilter': '清空搜尋條件',
  'common.research': '重新搜尋',
  // Add & edit magic connect modal
  'machineModal.placeHolder': '機台名不能為空',
  'machineModal.machineName': '機台名稱',
  'machineModal.machineName.invaild': '最多 50 個字',
  'machineModal.MACAddress': 'MAC Address',
  'machineModal.MACAddress.invaild': '請依照規則範例 00:11:99:AA:BB:FF 填寫',
  'machineModal.connectType': '連線類型',
  'machineModal.connectMode': '連線模式',
  'machineModal.connectType.placeholder': '選擇連接類型',
  'machineModal.connectMode.placeholder': '選擇連線模式',
  'machineModal.lng': '經度',
  'machineModal.lat': '緯度',
  'machineModal.description': '描述',
  'machineModal.description.invaild': '最多 200 個字',
  'machineModal.country': '國家',
  'machineModal.country.invaild': '最多 100 個字',
  'machineModal.city': '城市',
  'machineModal.city.invaild': '最多 100 個字',
  'machineModal.serialNumber': '序列號',
  'machineModal.serialNumber.invaild': '請輸入序列號',
  'machineModal.serialNumber.invaild12Letter': '僅能輸入英數 12 位元',
  'machineModal.remark': '備註',
  'machineModal.remark.invaild': '最多 100 個字',
  // sidebar
  'sidebar.home': '首頁',
  'sidebar.add': '新增',
  'sidebar.news': '消息',
  'sidebar.map': '地圖',
  'sidebar.logout': '登出',
  // my connect header
  'myConnect.header.updating.message': '等待更新資料',
  'myConnect.header.modify': '修改',
  'myConnect.header.delete': '刪除',
  'myConnect.header.delete.fail': '只有在離線狀態才能進行刪除',
  'myConnect.header.log': '日誌',
  'myConnect.header.refresh': '重新整理',
  'myConnect.header.connect': '連線',
  'myConnect.header.connect.userOffline': '使用者離線狀態下不能連線',
  'myConnect.header.connect.machineOffline': '機台離線狀態下不能連線',
  'myConnect.header.connect.machineConnected': '機台已連線或連線中狀態下不能連線',
  'myConnect.header.connect.machineAdminConnected': '正在連線狀態下不能再連線',
  'myConnect.header.disconnect': '斷線',
  'myConnect.header.disconnect.selfConnected': '只可以對自己連線的機台進行斷線',
  'myConnect.header.disconnect.connected': '只可以對連線中或已連線狀態下的機台進行斷線',
  'myConnect.header.binding': 'Binding',
  'myConnect.header.binding.machineConnected': '機台已連線或連線中狀態下不能 Binding',
  'myConnect.header.binding.success': 'Binding 成功',
  'myConnect.header.binding.fail': 'Binding 失敗',
  'myConnect.header.search': '搜尋',
  'myConnect.header.filter.macineCreator': '篩選出您創建的機台',
  // 取得 device
  'getDevice.fall': '取得設備失敗',
  // connectTable
  'connectTable.status': '狀態',
  'connectTable.action': '操作',
  'connectTable.name': '名字',
  'connectTable.description': '描述',
  'connectTable.userConnective': '連接者',
  'connectTable.country': '國家',
  'connectTable.city': '城市',
  'connectTable.remark': '備註',
  'connectTable.nodata': '無資料',
  'connectTable.On-Line': '在線上',
  'connectTable.Connected': '已連線',
  'connectTable.Connecting': '連線中',
  'connectTable.Off-Line': '離線',
  'connectTable.updating': '更新中',
  //connect machine
  'connectMachine.fail': '連線失敗',
  'connectMachine.connecting': '正在連線',
  'connectMachine.delete': '已經被刪除',
  'connectMachine.disconnect': '已經被斷線',
  //disconnect machine
  'disconnectMachine.success': '斷開連線成功',
  'disconnectMachine.fail': '斷開連線失敗',
  // news Modal
  'newsModal.allNews': '所有消息',
  'newsModal.noNews': '沒有最新消息',
  'newsModal.search.fail': '取得最新消息失敗',
  'newsModal.filterNoNews': '沒有搜尋到消息',
  // logs Modal
  'logs.noLogs': '沒有日誌',
  'logs.filterNoLogs': '沒有搜尋到日誌',
  // Log And news
  'logAndNews.back': '返回連線頁',
  'logAndNews.export': '導出',
  'logAndNews.log': '日誌',
  'logAndNews.undo': '清除搜尋',
  'logAndNews.getLogs.failed': '搜尋日誌失敗',
  // All Log filters
  'logAndNews.filters': '篩選',
  'logAndNews.filters.level': '級別',
  'logAndNews.filters.level.placeholder': '全部',
  'logAndNews.filters.startTime': '開始時間',
  'logAndNews.filters.endTime': '結束時間',
  'logAndNews.filters.action': '動作',
  'logAndNews.filters.action.placeholder': '全部',
  'logAndNews.filters.action.fix': '修復',
  'logAndNews.filters.action.alarm': '警告',
  'logAndNews.filters.action.maintain': '維修',
  'logAndNews.filters.action.release': '發布',
  'logAndNews.filters.action.add': '新增',
  'logAndNews.filters.action.connect': '連線',
  'logAndNews.filters.action.disconnect': '斷線',
  'logAndNews.filters.action.update': '更新',
  'logAndNews.filters.action.delete': '刪除',
  // Add device
  'deviceModal.name': '名稱',
  'deviceModal.name.invaild': '請輸入設備名稱',
  'deviceModal.ip': 'IP 地址',
  'deviceModal.mask': '遮罩',
  'deviceModal.descr': '描述',
  'deviceModal.type': '類型',
  'deviceModal.otherType': '其他類型',
  'deviceModal.type.placeholder': '選擇類型',
  'deviceModal.info': '資訊',
  'deviceModal.proto': '連線模式',
  // map
  map: '地圖',
  'map.note': '若尚未填入經緯度，並不會在此顯示',
  'map.connect': '連線'
};
export default zh;
